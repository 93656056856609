
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class PageSettingsIndex extends Vue {
    @Getter("auth/me") me!: CurrentAccount;

    account: Account | null = null;
}
